
const virtualRegionData = [

  { name: "阿坝", value: 46 },

  { name: "阿拉善盟", value: 60 },

  { name: "阿里地区", value: 50 },

  { name: "安顺", value: 60 },

  { name: "安阳", value: 60 },

  { name: "巴彦淖尔", value: 60 },

  { name: "巴中", value: 60 },

  { name: "白城", value: 60 },

  { name: "白山", value: 60 },

  { name: "白银", value: 60 },

  { name: "百色", value: 60 },

  { name: "包头", value: 230 },

  { name: "保山", value: 60 },

  { name: "北海", value: 60 },

  { name: "北京", value: 465 },

  { name: "本溪", value: 60 },

  { name: "毕节", value: 60 },

  { name: "滨州", value: 45 },

  { name: "昌都", value: 50 },

  { name: "常州", value: 2 },

  { name: "朝阳", value: 60 },

  { name: "潮州", value: 57 },

  { name: "池州", value: 60 },

  { name: "赤峰", value: 60 },

  { name: "崇左", value: 60 },

  { name: "楚雄", value: 60 },

  { name: "达州", value: 55 },

  { name: "大理", value: 60 },

  { name: "大庆", value: 60 },

  { name: "大同", value: 2150 },

  { name: "大兴安岭", value: 60 },

  { name: "丹东", value: 60 },

  { name: "德宏", value: 60 },

  { name: "德阳", value: 141 },

  { name: "德州", value: 34 },

  { name: "迪庆", value: 60 },

  { name: "定西", value: 60 },

  { name: "东营", value: 60 },

  { name: "鄂尔多斯", value: 60 },

  { name: "鄂州", value: 60 },

  { name: "恩施", value: 60 },

  { name: "防城港", value: 60 },

  { name: "佛山", value: 5 },

  { name: "抚顺", value: 60 },

  { name: "抚州", value: 60 },

  { name: "阜新", value: 60 },

  { name: "阜阳", value: 60 },

  { name: "甘南", value: 60 },

  { name: "甘孜", value: 60 },

  { name: "格尔木", value: 60 },

  { name: "固原", value: 60 },

  { name: "广安", value: 60 },

  { name: "广元", value: 60 },

  { name: "贵港", value: 60 },

  { name: "贵阳", value: 733 },

  { name: "桂林", value: 60 },

  { name: "果洛", value: 60 },

  { name: "哈尔滨", value: 8 },

  { name: "海北", value: 60 },

  { name: "海东", value: 60 },

  { name: "海口", value: 175 },

  { name: "海南", value: 60 },

  { name: "海西", value: 60 },

  { name: "杭州", value: 1552 },

  { name: "河池", value: 60 },

  { name: "河源", value: 55 },

  { name: "菏泽", value: 60 },

  { name: "贺州", value: 60 },

  { name: "鹤壁", value: 60 },

  { name: "红河", value: 60 },

  { name: "呼和浩特", value: 425 },

  { name: "呼伦贝尔", value: 60 },

  { name: "湖州", value: 50 },

  { name: "淮安", value: 257 },

  { name: "淮南", value: 60 },

  { name: "黄冈", value: 60 },

  { name: "黄南", value: 60 },

  { name: "黄山", value: 60 },

  { name: "黄石", value: 60 },

  { name: "鸡西", value: 60 },

  { name: "吉安", value: 709 },

  { name: "吉林", value: 60 },

  { name: "济宁", value: 30 },

  { name: "佳木斯", value: 60 },

  { name: "嘉峪关", value: 60 },

  { name: "江门", value: 51 },

  { name: "焦作", value: 60 },

  { name: "金昌", value: 60 },

  { name: "金华", value: 60 },

  { name: "晋城", value: 5819 },

  { name: "晋中", value: 2290 },

  { name: "荆门", value: 60 },

  { name: "荆州", value: 60 },

  { name: "景德镇", value: 60 },

  { name: "九江", value: 69 },

  { name: "开封", value: 58 },

  { name: "昆明", value: 1891 },

  { name: "拉萨", value: 25 },

  { name: "来宾", value: 60 },

  { name: "廊坊", value: 46 },

  { name: "乐山", value: 60 },

  { name: "丽江", value: 60 },

  { name: "连云港", value: 66 },

  { name: "凉山", value: 60 },

  { name: "辽阳", value: 60 },

  { name: "辽源", value: 60 },

  { name: "聊城", value: 58 },

  { name: "林芝", value: 50 },

  { name: "临沧", value: 60 },

  { name: "临汾", value: 5000 },

  { name: "临夏", value: 60 },

  { name: "柳州", value: 50 },

  { name: "六盘水", value: 60 },

  { name: "龙岩", value: 60 },

  { name: "陇南", value: 60 },

  { name: "娄底", value: 60 },

  { name: "泸州", value: 60 },

  { name: "吕梁", value: 3108 },

  { name: "洛阳", value: 103 },

  { name: "漯河", value: 60 },

  { name: "马鞍山", value: 60 },

  { name: "茂名", value: 56 },

  { name: "梅州", value: 60 },

  { name: "绵阳", value: 58 },

  { name: "牡丹江", value: 60 },

  { name: "那曲", value: 50 },

  { name: "南昌", value: 175 },

  { name: "南充", value: 55 },

  { name: "南京", value: 67 },

  { name: "南宁", value: 127 },

  { name: "南通", value: 591 },

  { name: "南阳", value: 45 },

  { name: "内江", value: 60 },

  { name: "宁波", value: 194 },

  { name: "宁德", value: 60 },

  { name: "怒江", value: 60 },

  { name: "攀枝花", value: 60 },

  { name: "盘锦", value: 60 },

  { name: "平顶山", value: 60 },

  { name: "平凉", value: 60 },

  { name: "萍乡", value: 60 },

  { name: "莆田", value: 60 },

  { name: "濮阳", value: 60 },

  { name: "普洱", value: 60 },

  { name: "七台河", value: 60 },

  { name: "齐齐哈尔", value: 60 },

  { name: "潜江", value: 60 },

  { name: "黔东南", value: 60 },

  { name: "黔南", value: 60 },

  { name: "黔西南", value: 60 },

  { name: "钦州", value: 60 },

  { name: "秦皇岛", value: 20 },

  { name: "青岛", value: 7 },

  { name: "庆阳", value: 60 },

  { name: "曲靖", value: 60 },

  { name: "衢州", value: 60 },

  { name: "泉州", value: 290 },

  { name: "日喀则", value: 45 },

  { name: "日照", value: 60 },

  { name: "三门峡", value: 60 },

  { name: "三明", value: 60 },

  { name: "厦门", value: 67 },

  { name: "山南", value: 50 },

  { name: "汕尾", value: 33 },

  { name: "商丘", value: 60 },

  { name: "上海", value: 48 },

  { name: "韶关", value: 60 },

  { name: "邵阳", value: 60 },

  { name: "绍兴", value: 40 },

  { name: "十堰", value: 60 },

  { name: "石嘴山", value: 60 },

  { name: "朔州", value: 900 },

  { name: "四平", value: 60 },

  { name: "松原", value: 60 },

  { name: "宿迁", value: 197 },

  { name: "绥化", value: 60 },

  { name: "随州", value: 60 },

  { name: "遂宁", value: 60 },

  { name: "台州", value: 51 },

  { name: "太原", value: 5868 },

  { name: "泰安", value: 231 },

  { name: "泰州", value: 68 },

  { name: "天津", value: 81214 },

  { name: "天水", value: 60 },

  { name: "铁岭", value: 60 },

  { name: "通化", value: 60 },

  { name: "通辽", value: 60 },

  { name: "铜陵", value: 60 },

  { name: "铜仁", value: 60 },

  { name: "潍坊", value: 60 },

  { name: "温州", value: 189 },

  { name: "文山", value: 60 },

  { name: "乌海", value: 60 },

  { name: "乌兰察布", value: 60 },

  { name: "乌鲁木齐", value: 580 },

  { name: "无锡", value: 127 },

  { name: "吴忠", value: 60 },

  { name: "芜湖", value: 69 },

  { name: "梧州", value: 60 },

  { name: "武汉", value: 1 },

  { name: "西宁", value: 73 },

  { name: "西双版纳", value: 59 },

  { name: "咸宁", value: 60 },

  { name: "襄阳", value: 18 },

  { name: "孝感", value: 50 },

  { name: "新乡", value: 88 },

  { name: "新余", value: 55 },

  { name: "信阳", value: 60 },

  { name: "兴安盟", value: 60 },

  { name: "许昌", value: 60 },

  { name: "雅安", value: 60 },

  { name: "烟台", value: 11 },

  { name: "延边", value: 60 },

  { name: "盐城", value: 1568 },

  { name: "扬州", value: 15 },

  { name: "阳江", value: 60 },

  { name: "阳泉", value: 1080 },

  { name: "伊春", value: 60 },

  { name: "宜宾", value: 60 },

  { name: "宜昌", value: 188 },

  { name: "宜春", value: 60 },

  { name: "益阳", value: 60 },

  { name: "银川", value: 290 },

  { name: "鹰潭", value: 60 },

  { name: "营口", value: 60 },

  { name: "永州", value: 60 },

  { name: "玉林", value: 85 },
  { name: "玉树", value: 60 },

  { name: "玉溪", value: 1818 },

  { name: "云浮", value: 60 },

  { name: "枣庄", value: 60 },

  { name: "湛江", value: 44 },

  { name: "张家界", value: 60 },

  { name: "张家口", value: 95 },

  { name: "张掖", value: 60 },

  { name: "漳州", value: 545 },

  { name: "长春", value: 210 },

  { name: "长沙", value: 36 },

  { name: "长治", value: 2570 },

  { name: "昭通", value: 60 },

  { name: "肇庆", value: 60 },

  { name: "镇江", value: 59 },

  { name: "中卫", value: 60 },

  { name: "舟山", value: 1 },

  { name: "株洲", value: 60 },

  { name: "珠海", value: 14 },

  { name: "驻马店", value: 60 },

  { name: "淄博", value: 60 },

  { name: "自贡", value: 60 },

  { name: "遵义", value: 60 }


]


export default { virtualRegionData};
